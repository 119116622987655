<template>
  <div class="role-binding">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="role-tabs">
      <el-tab-pane :label="$t('front-epwechat-module-v1-unbound-role')" name="Unbound"></el-tab-pane>
      <el-tab-pane :label="$t('front-epwechat-module-v1-bound-role')" name="Bound"></el-tab-pane>
    </el-tabs>
    <div>
      <!-- 搜索条件 -->
      <div class="role-search">
        <div>
          <el-input
            size="mini"
            placeholder="列表内搜索"
            v-model="condition"
            class="input-with-select"
            @change="seachBoundRole"
          >
            <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          <el-button size="mini" @click="seachBoundRole">{{ $t('label.tabpage.searchz') }}</el-button>
        </div>
        <div>
          <div v-if="activeName == 'Unbound' && !editAllBtn">
            <el-button size="mini" type="primary" @click="autoMate"
              >{{ $t('front-epwechat-module-v1-auto-mate') }}</el-button
            >
            <el-button size="mini" type="primary" @click="editAllBtn = true"
              >{{ $t('lable.product.pricebooks.editall') }}</el-button
            >
          </div>
          <div v-else-if="activeName == 'Unbound' && editAllBtn">
            <el-button size="mini" type="primary" @click="savebatchBoundEPDept"
              >{{ $t('label.tabpage.save') }}</el-button
            >
            <el-button size="mini" type="primary" @click="cancelbatchBoundEPDept"
              >{{ $t('label.tabpage.cancel') }}</el-button
            >
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <wechat-table
        class="table_height"
        :wechatdata="wechatdata"
        :showStar="true"
        :isShowOpration="true"
        :oprationBtnList="
          activeName == 'Unbound' ? (!editAllBtn ? unboundBtn : '') : boundBtn
        "
        :editAllBtn="editAllBtn"
        :editInner="editInner"
        :innerData="innerData"
        @Binding="Binding"
        @Save="Save"
        @cancel="cancel"
        @Rebind="Rebind"
        @Unbind="Unbind"
        @inLineEdit="inLineEdit"
        sign="role"
        :page="page"
        @goHomePage="goHomePage"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      ></wechat-table>
    </div>
    <!-- 解除绑定弹窗 -->
    <el-dialog :visible.sync="dialogUnbind" width="460px">
      <div slot="title" class="dialog-title">
        <i class="el-icon-warning-outline" style="color: #e6a23c"></i>
        <span class="title-text">&nbsp;&nbsp;{{ $t('label.prompt') }}</span>
      </div>
      <div>{{ $t('front-epwechat-module-v1-unbound-desc') }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogUnbind = false">{{ $t('label.tabpage.cancel') }}</el-button>
        <el-button size="mini" type="primary" @click="unboundEPDeptData"
          >{{ $t('label.tabpage.ok') }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as EPWeChat from "./api.js";
import wechatTable from "./components/wechatTable.vue";
export default {
  components: {
    wechatTable,
  },
  data() {
    return {
      wechatdata: {
        tabledata: [], //数据
        tablekey: [], //表头
      },
      page:{
        pageNum: 1,
        pageSize: 20,
        total: 100,
      },
      activeName: "Unbound",
      condition: "", //列表内搜索
      editAllBtn: false,
      editInner: false,//行内编辑
      innerData: {},//单行数据
      // isShowOpration: true, //显示操作列
      // 操作列功能按钮
      unboundBtn: [{ action: "Binding", label: this.$i18n.t('label.weixin.binding'), type: "primary" }],
      boundBtn: [
        { action: "Rebind", label: this.$i18n.t('front-epwechat-module-v1-again-binding'), type: "primary" },
        { action: "Unbind", label: this.$i18n.t('label.weixin.unbind'), type: "primary" },
      ],
      dialogUnbind: false, // 解除绑定弹窗
      rowData: "", //解绑该行数据
      currentBtntext: "",//绑定或重新绑定，用于提示成功信息
    };
  },
  mounted() {
    if (this.activeName == "Unbound") {
      this.getUnboundDeptData(this.page);
    } else if (this.activeName == "Bound") {
      this.getboundDeptData(this.page);
    }
  },
  methods: {
    /** tabs切换 */
    handleClick(tab) {
      this.editAllBtn = false;//防止在未绑定用户列表编辑全部，已绑定用户列表依旧是编辑状态。
      if (tab.name == "Unbound") {
        this.getUnboundDeptData(this.page);
      } else if (tab.name == "Bound") {
        this.getboundDeptData(this.page);
      }
    },
    /** 绑定 */
    Binding(row,btntext) {
      this.editInner = true
      this.innerData = row
      this.currentBtntext = btntext
    },
    /** 保存 */
    Save(row) {
      this.saveboundEPDept(row);
      this.editInner = false
    },
    /** 取消 */
    cancel() {
      this.seachBoundRole()
      this.editInner = false
    },
    /** 绑定角色 */
    
    saveboundEPDept(data) {
      EPWeChat.boundEPDept({
        cloudccdeptname: data.cloudccdeptname,
        cloudccdeptid: data.cloudccdeptid,
        epdeptid: data.epdeptid,
        epdeptname: data.epdeptname,
      }).then((res) => {
        if (10000 == res.returnCode) {
          this.seachBoundRole();
          // if(this.currentBtntext === "绑定") {
          //   // 绑定成功  label.mobile.bind.success
          //   this.$message.success(this.$i18n.t('label.mobile.bind.success'))
          // }else if(this.currentBtntext === "重新绑定") {
          //   // 重新绑定成功
          //   this.$message.success(this.$i18n.t('c173'))
          // }
          this.$message.success(this.currentBtntext+'成功')
        }
      });
    },
    /** 重新绑定 */
    Rebind(row,btntext) {
      this.editInner = true
      this.innerData = row
      this.currentBtntext = btntext
    },
    /** 解除绑定 */
    Unbind(row) {
      this.dialogUnbind = true;
      this.rowData = row;
    },
    /** 角色解绑 */
    unboundEPDeptData() {
      let data = this.rowData;
      EPWeChat.unboundEPDept({
        epdeptid: data.epdeptid,
        deptid: data.cloudccdeptid,
      }).then((res) => {
        if (10000 == res.returnCode) {
          this.dialogUnbind = false;
          this.seachBoundRole(this.page);
          // 解绑成功
          this.$message.success(this.$i18n.t('c41'))
        }
      });
    },
    /** 自动匹配 */
    autoMate() {
      EPWeChat.autoBoundEPDept().then((res) => {
        if (10000 == res.returnCode) {
          this.getUnboundDeptData(this.page);
          // 自动匹配成功
          this.$message.success(this.$i18n.t('c108'))
        }
      });
    },
    /**搜索 */
    seachBoundRole() {
      if (this.activeName == "Unbound") {
        this.getUnboundDeptData(this.page);
      } else if (this.activeName == "Bound") {
        this.getboundDeptData(this.page);
      }
    },
    /** 获取未绑定角色 */
    getUnboundDeptData(page) {
      EPWeChat.getUnboundDept({
        cloudccname: this.condition,
        count: page.pageSize,
        page: page.pageNum
      })
      .then(res => {
        if (1 == res.returnCode) {
          let data = res.data
          this.wechatdata.tabledata = data.tabledata
          this.wechatdata.tablekey = data.tablekey
           // 分页
          this.page.pageNum = data.page
          this.page.pageSize = data.count
          this.page.total = data.totalpage
        }
      })
    },
    /** 获取已绑定角色 */
    getboundDeptData(page) {
      EPWeChat.getboundDept({
        condition: this.condition,
        count: page.pageSize,
        page: page.pageNum
      }).then((res) => {
        if (10000 == res.returnCode) {
          let data = res.data
          this.wechatdata.tabledata = data.tabledata;
          this.wechatdata.tablekey = data.tablekey;
           // 分页
          this.page.pageNum = data.page
          this.page.pageSize = data.count
          this.page.total = data.totalpage
        }
      });
    },
    /** 去首页 */
    goHomePage(){
      this.page.pageNum = 1
      this.seachBoundRole()
    },
    handleSizeChange(val){
      this.page.pageSize = val
      this.seachBoundRole()
    },
    handleCurrentChange(val){
      this.page.pageNum = val
      this.seachBoundRole()
    },
    /** 取消批量保存 */
    cancelbatchBoundEPDept() {
      this.editAllBtn = false;
      this.getUnboundDeptData(this.page);
    },
    /** 批量绑定角色 */
    savebatchBoundEPDept() {
      let params = [];
      let data = this.wechatdata.tabledata;
      data.forEach(item => {
        if (item.epdeptid != "" && item.epdeptname != "") {
          let obj = {}
          obj['cloudccdeptname'] = item.cloudccdeptname
          obj['cloudccdeptid'] = item.cloudccdeptid
          obj['epdeptid'] = item.epdeptid
          obj['epdeptname'] = item.epdeptname
          params.push(obj)
        }
      });
      
      EPWeChat.batchBoundEPDept(params).then((res) => {
        if (10000 == res.returnCode) {
          this.editAllBtn = false;
          this.getUnboundDeptData(this.page);
          // 编辑全部成功
          this.$message.success(this.$i18n.t('c174'))
        }
      });
    },
  },
};
</script>
<style lang="scss">
.role-binding {
  margin: 15px 10px;
  .role-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .input-with-select {
      width: 280px;
      margin-right: 10px;
    }
  }
}
.role-tabs{
  .is-active{
    color: #2d6cfc !important;
  }
  .el-tabs__active-bar{
    background-color: #2d6cfc !important;
  }
}
.table_height{
  height: calc(100vh - 320px - 50px);
}
</style>